import 'nodelist-foreach'
import indexOf from 'utils/dom-index-of'

export default ({
  table = document.querySelector('table'),
  sortableSelector = 'th[data-sortable]',
  containerSelector = 'tbody',
  compareFunction = function (rowA, rowB, direction, colIndex) {
    const a = rowA.children[colIndex].innerHTML
    const b = rowB.children[colIndex].innerHTML
    const sgn = direction === 'asc' ? 1 : -1
    return a.toUpperCase() < b.toUpperCase() ? -sgn : sgn
  }
} = {}) => {
  if (!table) return

  let sortableCols = table.querySelectorAll(sortableSelector)
  if (sortableCols) {
    sortableCols.forEach(col => {
      if (col.hasAttribute('data-sort')) sort(col)
      col.addEventListener('click', () => sort(col, { flip: true }))
    })
  }

  return {
    destroy: () => {
      sortableCols.forEach(col => col.removeEventListener('click', () => sort(col)))
      sortableCols = undefined
    }
  }

  function sort (column = this, { flip = false } = {}) {
    const direction = flip
      ? flipDirection(column.getAttribute('data-sort'))
      : column.getAttribute('data-sort')

    sortableCols.forEach(col => col.removeAttribute('data-sort'))
    column.setAttribute('data-sort', direction)

    const container = table.querySelector(containerSelector)
    if (!container) return

    const colIndex = indexOf(column)
    const sortedRows = Array
      .from(container.children)
      .sort((a, b) => compareFunction(a, b, direction, colIndex))

    container.innerHTML = ''
    sortedRows.forEach(row => container.appendChild(row))
  }

  function flipDirection (dir = 'asc') {
    return dir === 'asc' ? 'desc' : 'asc'
  }
}
