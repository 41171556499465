import LazyLoad from 'controllers/lazy-load'
import Tooltip from 'controllers/tooltip'
import TableSort from 'controllers/table-sort'
import Photoswipe from 'controllers/photoswipe'

TableSort()
Photoswipe(document.querySelectorAll('.project__gallery figure'))
Tooltip(document.querySelectorAll('[data-tooltip]'))
LazyLoad(document.body.querySelectorAll('figure img'))

// Make clickable all table rows with a [data-href]
Array.from(document.querySelectorAll('tr[data-href]')).forEach(row => {
  row.addEventListener('click', e => {
    const url = row.getAttribute('data-href')
    if (e.which > 1 || e.shiftKey || e.altKey || e.metaKey || e.ctrlKey) {
      window.open(url, '_blank')
    } else {
      window.location = url
    }
  })
})
