import 'nodelist-foreach'
import tippy from 'tippy.js'

export default function (elements) {
  elements.forEach(element => {
    const content = element.getAttribute('data-tooltip').replace(/\n/g, '<br/>')
    if (!content) return
    element.classList.add('has-tippy')
    tippy(element, { content, allowHTML: true })
  })
}
