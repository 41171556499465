import 'nodelist-foreach'
import PhotoSwipe from 'photoswipe'
import PhotoSwipeUIDefault from 'photoswipe/dist/photoswipe-ui-default'

let photoswipe
const CONTAINER = document.querySelector('.pswp')
const OPTIONS = {
  bgOpacity: 1,
  history: false,
  allowPanToNext: false,
  barsSize: { top: 0, bottom: 'auto' },
  captionEl: true,
  fullscreenEl: false,
  zoomEl: false,
  shareEl: false,
  counterEl: true,
  preloaderEl: true,
  showHideOpacity: true,
  getThumbBoundsFn: (index) => {
    const slide = photoswipe.items[index]
    if (!slide) return

    const pageYScroll = window.pageYOffset || document.documentElement.scrollTop
    const { left, top, width } = slide.element.getBoundingClientRect()
    return {
      x: left,
      y: top + pageYScroll,
      w: width
    }
  }
}

export default function (figures) {
  if (!CONTAINER) return
  if (!figures || !figures.length) return

  const slides = []
  figures.forEach((figure, index) => {
    const img = figure.querySelector('img')
    const figcaption = figure.querySelector('figcaption')

    figure.classList.add('has-photoswipe')
    slides.push({
      index,
      element: img,
      title: figcaption && figcaption.innerHTML,
      src: img.getAttribute('data-zoom-src'),
      w: img.getAttribute('data-width'),
      h: img.getAttribute('data-height')
    })

    figure.addEventListener('click', e => {
      photoswipe = new PhotoSwipe(CONTAINER, PhotoSwipeUIDefault, slides, Object.assign(OPTIONS, { index }))
      photoswipe.init()
    })
  })
}
